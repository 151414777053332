<template>
  <div id="app">
    <v-app-bar
      color="deep-purple accent-4"
      dense
      dark

      style="background:#003F74!important"
    >

      <v-toolbar-title @click="$router.push('/')"><img :src="logo" style="width:30px;height:30px;margin-top:-5px"> KES LAKAY</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    
      <v-main>
        <v-sheet
    class="overflow-hidden appbarsheet"
    style="position: relative;"
  >

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="sidebar_user_name">{{ getAuthenticatedUser() }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link :to="item.link"
        >
          <v-list-item-icon>
            <v-icon style="color:#F68D3A!important">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          key="Log Out"
          v-on:click="logout"
        >
          <v-list-item-icon v-if=!loading>
            <v-icon style="color:red!important">mdi-power-standby</v-icon>
          </v-list-item-icon>

          <v-progress-circular v-if=loading
      indeterminate
      color="red"
    ></v-progress-circular>

          <v-list-item-content>
            <v-list-item-title>Déconnexion</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
      
        <router-view></router-view>
        
        </v-sheet>
      </v-main>
  </div>
</template>
<script type="text/javascript">
  import logo from "/src/assets/img/white_emblem.png"

  export default {
  data () {
      return {
        drawer: null,
        filterdialog: false,
        logo: logo,
        loading: false,
        items: [
          { title: 'Comptes', icon: 'mdi-account-circle-outline', link: '/accounts' },
          { title: 'Souscriptions', icon: 'mdi-cellphone', link: '/subscriptions' },
          { title: 'Renouvellements', icon: 'mdi-autorenew', link: '/renewals' },
          { title: 'Utilisateurs', icon: 'mdi-cog-outline', link: '/settings' },
          { title: 'Gestion APK', icon: 'mdi-code-array', link: '/apk' },
        ],
      }
    },
    methods: {
      logout: async function(){
        this.loading = true
        await this.$store.dispatch('LogOut')
        this.$router.push('/login')
        
      },
      getAuthenticatedUser(){
        return this.$store.getters.StateUser.full_name
      }
    }
};
</script>
<style lang="scss">
.sidebar_user_name{
  margin-top:15px;
  text-align:center;
  font-weight:600!important;
  margin-right:10px;
}
.theme--dark.v-app-bar.v-toolbar.v-sheet {
    background-color: #F68D3A!important;
}
.v-toolbar__title{
  cursor:pointer;
}
.v-toolbar__content, .v-toolbar__extension{
  padding:4px 0px!important;
}
.v-list-item__title{
  font-size:15px;
  margin-left:10px;
  font-weight:400;
}

.v-list-item .v-progress-circular{
  color:red!important;
  width:25px!important;
  height:25px!important;
  margin-top:0px!important;
}

.v-list-item .v-progress-circular .v-progress-circular__overlay{
  color:red!important;
}
.appbarsheet{
  min-height: 100vh;
}

.v-breadcrumbs__item{
  color:black;
}
.v-breadcrumbs {
  background:#f3f3f3;
  border-bottom:1px solid #f2f2f2;
  padding: 10px 12px!important;
}
.container-xl, .container-lg, .container-md, .container-sm, .container{
  max-width:3000px!important;
}
.v-toolbar__title{
  padding-left:10px!important;
}
.v-list-item:last-child i, .v-list-item:last-child .v-list-item__content{
  color:red;
}

.v-navigation-drawer__content{
  overflow-y:hidden!important;
}
.v-application .v-dialog .v-btn{
width:80px!important;
    margin-right:5px;
    margin-top:-5px!important;
  }
  .v-list-item i, .v-list-item .v-list-item__content, .v-list-item:last-child i, .v-list-item:last-child .v-list-item__content{
      color:black!important;
    }
</style>
